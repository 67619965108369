.app-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 5rem;
  width: 5rem;
}

.backdrop {
  position: absolute;
  top: 0;

  height: 100vh;
  width: 100vw;
  background: #343a40;
  opacity: 0.95;
  z-index: 9999;
}
