@import 'bootstrap-custom';
@import 'colors';
@import 'animations';

* {
  font-family: 'Open Sans', sans-serif;
  color: $font-color;
  // this should not be touched every
  // the rest of the font sizes are relative to this
  // for the rest of font sizes use em instead of px
  // and for other sizes use rem instead of px
  font-size: 16px;
}

h1 {
  font-size: 1.75em;
  font-weight: bold;
  color: $dark-gray;
}

.step-title {
  color: $font-color;
  font-size: 0.88em;
  font-weight: 600;
}

.section-title {
  color: $dark-gray;
  font-size: 1.13em;
  font-weight: 600;
}

.action-button {
  width: 15.88rem;
  height: 2.19rem;
}

.button-icon {
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}

.page-explanation {
  font-size: 0.88em;
}

.action-button-icon {
  color: $primary-color;
  margin-right: 1rem;
  cursor: pointer;
}

.flex-container {
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
}
.align-center {
  align-items: center;
}

.flex-container-column {
  display: flex;
  flex-direction: column;

  &.align-center {
    justify-content: center;
    align-items: initial;
  }
}

tbody {
  tr {
    border-left: solid 3px transparent;
    &:hover {
      background-color: $background-white;
      border-left: solid 3px $primary-color;
    }
  }
}

.button-spinner {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1rem;
}
#tooltip-top{
  inset: auto auto -15px 10px!important;
}
.tooltip > .tooltip-inner, .tooltip.show > .tooltip-inner {
  background-color: #fedcce;
  color: #fb4c06;
  max-width: 700px;
  border: 1px solid #fb4c06;
  border-radius: 10;
}

.tooltip >.arrow, .tooltip.show > .arrow {
  border-top: 0px solid #fedcce;
  transform: translate(6px, 0px)!important;
  height:.5rem;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #fb4c06;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #fb4c06;
}
.tooltip-inner{
  border-radius: .8rem;
}

.req-field:after {
  content:" *";
  color:red;
}