$primary-color: #fb4c06;
$white: #ffffff;
$font-color: #5a5959;
$primary-font-color: #0d1231;
;
$secondary-font-color: #aaaaaa;
$warn: #d31f1f;
$error: #e50000;
$dark-gray: #1e1e20;
$almost-white: #dfdfdf;
$background-white: #f8f8f8;
$true-black: #000000;
$success-green: #00b252;
$primary-font-color: #0d1231;


