@import 'colors';

.btn {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 11.3rem;
  height: 2.2rem;
  border-radius: 16px;
  border-color: $primary-color;

  &:hover {
    border-color: $primary-color;
    opacity: 0.7;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:active {
    border-color: $warn !important;
    opacity: 1 !important;
  }

  &:disabled {
    border-color: $secondary-font-color;
    opacity: 1 !important;
  }
}

.input-group {
  .form-control {
    border-left: none;
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.input-group-prepend {
  span {
    background-color: #f8f8f8;
    border-radius: 16px;
    border: solid 1px #dfdfdf;
    height: 2.2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.disabled {
    span {
      border: none;
      background-color: $white;
    }
  }

  &.is-invalid {
    span {
      border: solid 1px #dc3545;
    }
  }
}

.form-control {
  border-radius: 16px;
  border: solid 1px $almost-white;
  background-color: $background-white !important;
  height: 2.2rem;
  max-width: 21.88rem;
  font-size: 0.88em;

  &.with-suffix {
    max-width: 19.7rem;
  }

  &:disabled {
    background-color: $white !important;
    border: none;
    -webkit-appearance: none;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $almost-white;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="%235a5959" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>');
  padding: 0.5em;
  padding-right: 1.5em;
}

// Remove arrow from input with type number

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
// --------------------------------------------

label {
  font-size: 0.75em;
  font-weight: bold;
  color: $secondary-font-color;
}

.dropdown-menu {
  border-radius: 27px;
  border: solid 0.5px $almost-white;
  box-shadow: 0 4px 16px 0 rgba(223, 223, 223, 0.45);
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: $true-black;

  i {
    color: $true-black;
  }
}

// TABLE -----------------
.table-responsive {
  padding-left: 1.88rem;
  min-height: 50vh;
}

// eliminates table top border
.table {
  margin-top: 1rem;

  th {
    border-top: none;
    border-bottom: none;

    font-weight: 700;
    padding-bottom: 1.5rem;
  }

  thead th {
    border-bottom: none;
  }

  td:last-child {
    border-top: none;
  }

  tbody {
    tr:first-child {
      td {
        border-top: none;
      }
    }
  }

  .inactive-program {
    td {
      color: $secondary-font-color;
    }
  }

  th {
    font-size: 0.88em;
  }

  td {
    font-size: 0.88em;
  }
}

.table-container {
  max-height: 60vh;
  overflow: auto;
}

// --------------------------

// SWITCH ---------------
.custom-control-input:checked ~ .custom-control-label::before {
  cursor: pointer;
  border-color: $primary-color;
  background-color: $primary-color;
  box-shadow: none !important;
}

.custom-control-input:disabled:checked ~ .custom-control-label::before {
  cursor: default;
  border-color: rgba(0, 0, 0, 0);
  background-color: lightgray !important;
}

.custom-control-input:disabled:checked ~ .custom-control-label::after {
  cursor: default;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-switch .custom-control-label::after {
  cursor: pointer;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #aaaaaa;
}

.custom-control-input:enabled ~ .custom-control-label {
  color: #6c757d;
}
// ---------------------

/* Track */
/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $font-color;
  border-radius: 27px;
}

.toast.show {
  position: absolute;
  background-color: rgba(0, 178, 82, 0.1);
  right: 3.5rem;
  min-width: 16.9rem;
  border-radius: 16px;

  .toast-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 600;
    font-size: 0.88em;

    &.success {
      color: $success-green;
    }
    &.error {
      color: $warn;
    }

    .success-icon {
      color: $success-green;
    }

    .error-icon {
      color: $warn;
    }
  }
}

.custom-checkbox{
  .custom-control-label{
    &::before{
      top:0;
    }
    &::after{
      top:0;
    }
  }
}
